<template>
    <div class="columns">
        <div class="sidebar-left sidebar-left-full">
            <div class="table-scroller table-shadow">
                <table>
                    <thead>
                        <tr>
                            <th colspan="2">{{text.messages}}</th>
                        </tr>
                    </thead>
                    <tbody v-if="activeTableData.length > 0">
                        <tr v-for="row in activeTableData" :key="row.id">
                            <td><router-link :to="{ name: 'AdminMessagesView', params: { 'id' : row.id } }" :title="text.view + ' ' + row.headline" class="default-action">{{row.headline}}</router-link></td>
                            <td class="text-right table-meta">{{row.messageDate | formatDate}}</td>
                        </tr>
                        <tr>
                            <td colspan="2"><router-link :to="{ name: 'AdminMessagesList' }" :title="text.view_all_messages" class="btn btn-primary btn-noyspace btn-small">{{text.view_all_messages}}</router-link></td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="2">{{text.no_results}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="sidebar-right sidebar-right-full">
            <div class="table-scroller table-shadow">
                <table>
                    <thead>
                        <tr>
                            <th>{{text.report_total_advert_published}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="showTotalAdvertsPublishedGraph">
                            <td><ChartVerticalBarReportTotalAdvertsPublished :chartData="totalAdvertsPublishedGraphData" /></td>
                        </tr>
                        <tr v-else>
                            <td>{{text.no_results}}</td>
                        </tr>
                        <tr>
                            <td><router-link :to="{ name: 'AdminReporting' }" :title="text.view_all_reports" class="btn btn-primary btn-noyspace btn-small">{{text.view_all_reports}}</router-link></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {log} from "@/shared/utils";
    import lang from "@/shared/lang";

    import {mapActions, mapState} from "vuex";

    import {formatContentMixin} from "@/views/mixins/formatContent";
    import {messagesActionsMixin} from "@/views/mixins/messagesActions";
    import {reportActionsMixin} from "@/views/mixins/reportActions";

    import ChartVerticalBarReportTotalAdvertsPublished from "@/components/chart-verticalbar-reporttotaladvertspublished";

    export default {
        name: 'Home',
        components: {ChartVerticalBarReportTotalAdvertsPublished},
        mixins: [formatContentMixin, messagesActionsMixin, reportActionsMixin],
        data() {
            return {
                activeTableData: [],
                showTotalAdvertsPublishedGraph: false,
                totalAdvertsPublishedGraphData : [],
            }
        },
        computed: {
            ...mapState(['user', 'pageTitle']), // imports from store
            text() {
                return lang;
            },
        },
        methods: {
            ...mapActions(["setPageTitleAction"]),
            async refreshMessagesList(){
                await this.loadAdminActiveMessagesPreviewList(5);
            },
        },
        async mounted() {
            log(["Admin home mounted", this.user.name]);

            // Set Page Title
            this.setPageTitleAction({ title : this.pageTitle.title.replace("%s%", this.user.name) });

            this.refreshMessagesList(1);
            await this.getTotalAdvertsPublishedGraphData();
        },

    }
</script>