import { render, staticRenderFns } from "./_AdminWrapper.vue?vue&type=template&id=241634e9&scoped=true&"
import script from "./_AdminWrapper.vue?vue&type=script&lang=js&"
export * from "./_AdminWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "241634e9",
  null
  
)

export default component.exports