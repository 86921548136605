<template>
    <div>
        <Header />

        <SystemMessage />

        <main class="root-element">
            <Breadcrumbs />
            <PageTitle />
            <Help />

            <router-view />
        </main>

        <Footer />
    </div>
</template>

<script>
    import Breadcrumbs from "@/components/breadcrumbs";
    import PageTitle from "@/components/pagetitle";
    import Help from "@/components/help";
    import Header from '@/components/header';
    import Footer from '@/components/footer';
    import SystemMessage from '@/components/systemmessage';

    export default {
        name: "AdminWrapper",
        components: {Breadcrumbs, PageTitle, Help, Header, Footer, SystemMessage}

    }
</script>

<style lang="scss" scoped>

</style>